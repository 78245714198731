import React, { createContext, FC, useContext } from 'react';
import {
  IProjectFoldersTableHookLoadParams,
  IProjectFoldersTableHookValue,
  useProjectFoldersTableHook,
} from './projectFoldersTableHook';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import { useComponentContext as useMyTask } from 'contexts/MyTask';
import { useQuery } from '@apollo/client';
import { GET_CONTRACTS_DIVISIONS } from 'graphql/legalFolders/divisions';
import { contractsDivisionsList } from 'graphql/legalFolders/types/contractsDivisionsList';

export interface IContextState extends IProjectFoldersTableHookValue {
  myTask: boolean;
  filterOptions?: any;
  filterValues?: any;
}

export interface IContextActions {
  clearAllFilters: any;
  setMyTask: (myTasks: boolean) => void;
  onProjectFolderSelect: (id: string) => boolean;
}

const initialState: IContextState = {
  loading: false,
  projectFolders: [],
  filterApplied: {},
  loadPage: () => {},
  onFilterChange: () => true,
  pageLoadParams: { page: 0, rowsPerPage: 0 },
  totalItems: 0,
  myTask: true,
  filterOptions: {},
  error: undefined,
};

const defaultFilterValues = {};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IProjectFoldersTableHookLoadParams>;
  tableStorageKey?: string;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  children,
  overridePageLoadParams,
  initPageLoadParams,
  tableStorageKey,
}) => {
  const history = useHistory();

  const { myTask, setMyTask } = useMyTask();

  const {
    totalItems,
    projectFolders,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    error,
  } = useProjectFoldersTableHook({
    myTask,
    initPageLoadParams,
    overridePageLoadParams,
    tableStorageKey,
  });

  const onProjectFolderSelect = useCallback(
    (id: string) => {
      history.push(paths.treeViewClient.TREEVIEW_PROJECT_FOLDER.replace(':id', id));
      return true;
    },
    [history]
  );

  const { data: divisionsData } = useQuery<contractsDivisionsList>(GET_CONTRACTS_DIVISIONS);

  const filterOptions = useMemo(() => {
    const getDivisionsPairs = () => {
      return (
        divisionsData?.divisions.map(({ id, name }) => {
          return {
            id,
            name,
          };
        }) || []
      );
    };

    return {
      documentsDivisions: [...getDivisionsPairs()],
    };
  }, [
    divisionsData,

    // Prepared to filter by Owner
    // ,ownersData
  ]);

  const clearAllFilters = () => {
    onFilterChange({ ...overridePageLoadParams?.filterValues, ...defaultFilterValues });
  };

  return (
    <ComponentContext.Provider
      value={{
        totalItems,
        projectFolders,
        loadPage,
        pageLoadParams,
        loading,
        onFilterChange,
        filterApplied,
        clearAllFilters,
        myTask,
        setMyTask,
        onProjectFolderSelect,
        filterOptions,
        filterValues: pageLoadParams.filterValues || defaultFilterValues,
        error,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
