import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';

import { forEach } from 'lodash';
import { SortOrder } from './components/HeaderCell/HeaderCell';
import { IHeadCell, TableHead } from './components/TableHead/TableHead';

import cn from 'classnames';
import { DEBOUNCE_TIMEOUT, TABLE_PAGINATION } from 'constants/config';
import { debounce } from 'lodash';
import { FiltersRow } from './components/FiltersRow/FiltersRow';
import { IPagination, Pagination } from './components/Pagination/Paginations';
import s from './style.module.scss';

const MAX_SORT_COLUMNS = 5;
export interface ITableProps {
  headCells: IHeadCell[];
  dataCells: Array<any>;
  handleSelect?: (id: string) => boolean;
  totalItems: number;
  loadPage?: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => boolean;
  initRowsPerPage?: number;
  hideFilters?: boolean;
  printView?: boolean;
  initOrderByColumns?: IOrderByColumn[];
  initPage?: number;
  paginationProps?: Partial<IPagination>;
  stickyHeader?: boolean;
  maxHeight?: string;
  paginationSideComponent?: any;
}

export interface IOrderByColumn {
  order: SortOrder;
  orderBy: string;
}

export const Table: FC<ITableProps> = ({
  loadPage,
  totalItems,
  handleSelect,
  dataCells,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
  hideFilters,
  printView,
  initOrderByColumns,
  initPage,
  paginationProps,
  stickyHeader,
  maxHeight,
  paginationSideComponent,
}) => {
  const [orderByColumns, setOrderByColumns] = React.useState<IOrderByColumn[] | undefined>(
    initOrderByColumns ? [...initOrderByColumns] : undefined
  );

  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(initPage || 0);

  useEffect(() => {
    if (initPage !== undefined) {
      setPage(initPage);
    }
  }, [initPage]);

  const [rowsPerPage, setRowsPerPage] = React.useState(initRowsPerPage || 10);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        setPage(0);
      }
    },
    [onFilterChange]
  );

  const refresh = useCallback(() => {
    if (loadPage) {
      loadPage(orderByColumns, page, rowsPerPage);
    }
  }, [orderByColumns, page, rowsPerPage, loadPage]);

  useEffect(() => {
    refresh();
  }, [orderByColumns, page, rowsPerPage, refresh]);

  const handleRequestSetSort = useCallback(
    (x: { sort: SortOrder; sortOrder: number } | undefined, property: string) => {
      setOrderByColumns((old: IOrderByColumn[] | undefined) => {
        if (x && x?.sortOrder < 0) {
          return undefined;
        }

        if (!old) {
          if (x) {
            return [{ order: x.sort, orderBy: property }];
          }
          return;
        }

        const newList = [...old];
        const index = old.findIndex((sort) => sort.orderBy === property);
        if (index >= 0) {
          newList.splice(index, 1);
        }
        if (!x) {
          return newList;
        }

        if (x.sortOrder > newList.length) {
          newList.push({ order: x.sort, orderBy: property });
        } else {
          newList.splice(x.sortOrder - 1, 0, { order: x.sort, orderBy: property });
        }

        if (newList.length > MAX_SORT_COLUMNS) {
          return newList.slice(0, MAX_SORT_COLUMNS);
        }

        return newList;
      });
    },
    []
  );

  const handleRequestSort = useCallback((event: React.MouseEvent<unknown>, property: string) => {
    setOrderByColumns((old: IOrderByColumn[] | undefined) => {
      if (!old) {
        return [{ order: SortOrder.ASC, orderBy: property }];
      }

      const index = old.findIndex((sort) => sort.orderBy === property);
      if (index < 0) {
        if (old.length < MAX_SORT_COLUMNS) {
          return [...old, { order: SortOrder.ASC, orderBy: property }];
        }
        return [...old.slice(0, -1), { order: SortOrder.ASC, orderBy: property }];
      }

      const { order } = old[index];
      const newList = [...old];
      if (order === SortOrder.DESC) {
        newList.splice(index, 1);
      } else {
        newList[index].order = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      }
      return newList;
    });
  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      if (!handleSelect || !handleSelect(name)) newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight }}>
        <MuiTable stickyHeader={stickyHeader}>
          <MuiTableHead>
            <TableHead
              headCells={headCells}
              onRequestSort={handleRequestSort}
              onRequestSetSort={handleRequestSetSort}
              orderByColumns={orderByColumns}
              printView={printView}
              stickyHeader={stickyHeader}
            ></TableHead>
            {!hideFilters ? (
              <FiltersRow
                headCells={headCells}
                filterOptions={filterOptions}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                stickyHeader={stickyHeader}
              ></FiltersRow>
            ) : undefined}
          </MuiTableHead>
          <TableBody>
            {dataCells?.map((row, index) => {
              const isItemSelected = isSelected(row.id.toString());
              const columns: any[] = [];
              let count = 0;
              let colSpan = 0;
              forEach(headCells, (headCell) => {
                count++;
                if (colSpan > 1) {
                  colSpan--;
                  return undefined;
                }
                if (headCell.colSpan && headCell.colSpan > 1) {
                  colSpan = headCell.colSpan;
                }
                const cellClassName = cn({
                  [s.nowrap]: headCell.dataCell?.wrap === 'nowrap',
                  [s.box]: headCell.dataCell?.wrap === 'box',
                  [s.paddingHalf]: headCell.dataCell?.padding === 'half',
                  [s.noPaddingLeft]:
                    headCell.dataCell?.connected &&
                    ['left', 'middle'].includes(headCell.dataCell.connected),
                  [s.noPaddingRight]:
                    headCell.dataCell?.connected &&
                    ['middle', 'right'].includes(headCell.dataCell.connected),
                  [s.shrinkToContent]: headCell.dataCell?.shrink === 'content',
                });

                const index =
                  orderByColumns?.findIndex(
                    ({ orderBy }) => orderBy === headCell.sortBy || orderBy === headCell.id
                  ) ?? -1;
                const sorted = index >= 0;
                const { order } =
                  orderByColumns && sorted ? orderByColumns[index] : { order: SortOrder.ASC };

                if (count === 1)
                  columns.push(
                    <TableCell
                      component="th"
                      id={`tc-${index}`}
                      scope="row"
                      padding="none"
                      key={count.toString() + '_' + headCell.id}
                      align={headCell.dataCell?.align}
                      colSpan={headCell.colSpan}
                      className={cellClassName}
                      title={
                        headCell.dataCell?.wrap === 'box'
                          ? headCell.transformFunction
                            ? headCell.transformFunction(row[headCell.id], row, order, sorted)
                            : row[headCell.id]
                          : undefined
                      }
                    >
                      {headCell.transformDataCell ? (
                        <headCell.transformDataCell
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={sorted}
                        ></headCell.transformDataCell>
                      ) : headCell.transformFunction ? (
                        headCell.transformFunction(row[headCell.id], row, order, sorted)
                      ) : (
                        row[headCell.id]
                      )}
                    </TableCell>
                  );
                else {
                  columns.push(
                    <TableCell
                      align={headCell.dataCell?.align || 'left'}
                      key={count.toString() + '_' + headCell.id}
                      colSpan={headCell.colSpan}
                      className={cellClassName}
                      title={
                        headCell.dataCell?.wrap === 'box'
                          ? headCell.transformFunction
                            ? headCell.transformFunction(row[headCell.id], row, order, sorted)
                            : row[headCell.id]
                          : undefined
                      }
                    >
                      {headCell.transformDataCell ? (
                        <headCell.transformDataCell
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={sorted}
                        ></headCell.transformDataCell>
                      ) : headCell.transformFunction ? (
                        headCell.transformFunction(row[headCell.id], row, order, sorted)
                      ) : (
                        row[headCell.id]
                      )}
                    </TableCell>
                  );
                }
              });
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onClick={debounce(
                    (event: any) => handleClick(event, row.id.toString()),
                    DEBOUNCE_TIMEOUT
                  )}
                  style={{ cursor: 'pointer' }}
                >
                  {columns}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <Pagination
        rowsPerPageOptions={TABLE_PAGINATION}
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChanged={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        leftSideComponent={paginationSideComponent}
        {...paginationProps}
      ></Pagination>
    </div>
  );
};
