import React, { createContext, FC, useContext } from 'react';

import { useCallback, useMemo } from 'react';

import {
  IArchiveFilesTableHookLoadParams,
  IArchiveFilesTableHookValue,
  useArchiveFilesTableHook,
} from 'hooks/archiveFilesHook';
import { blobSizeRange } from 'constants/blobSizeRange';
import { useArchiveFileExtensions } from 'hooks/archiveFileExtensionsHook';
import { useArchiveFileMimeTypes } from 'hooks/archiveMimeTypesHook';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths/client';

export interface IContextState extends IArchiveFilesTableHookValue {
  filterOptions?: any;
  filterValues?: any;
}

export interface IContextActions {
  clearAllFilters: any;
  setMyTask: (myTasks: boolean) => void;
  onArchiveFileSelect: (archiveFileId: string) => boolean;
}

const initialState: IContextState = {
  loading: false,
  documents: [],
  filterApplied: {},
  loadPage: () => {},
  onFilterChange: () => true,
  pageLoadParams: { page: 0, rowsPerPage: 0 },
  totalItems: 0,
  filterOptions: {},
  error: undefined,
};

const defaultFilterValues = {
  blobSize: { id: 'All', name: 'All' },
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IArchiveFilesTableHookLoadParams>;
  tableStorageKey?: string;
  children: any;
}

export const ArchiveFilesProvider: FC<IProviderProps> = ({
  children,
  overridePageLoadParams,
  initPageLoadParams,
  tableStorageKey,
}) => {
  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    error,
  } = useArchiveFilesTableHook({
    initPageLoadParams,
    overridePageLoadParams,
    tableStorageKey,
  });

  const { archiveFileMimeTypePairs } = useArchiveFileMimeTypes();
  const { archiveFileExtensionPairs } = useArchiveFileExtensions();
  const history = useHistory();

  const onArchiveFileSelect = useCallback(
    (archiveFileId: string) => {
      history.push(paths.ARCHIVE_FILE_DETAILS.replace(':archiveFileId', archiveFileId));
      return true;
    },
    [history]
  );

  const filterOptions = useMemo(() => {
    return {
      mimeType: [...archiveFileMimeTypePairs],
      originalFileExtension: [...archiveFileExtensionPairs],
      blobSize: [{ id: 'All', name: 'All' }, ...blobSizeRange],
    };
  }, [archiveFileMimeTypePairs, archiveFileExtensionPairs]);

  const clearAllFilters = () => {
    onFilterChange({ ...overridePageLoadParams?.filterValues, ...defaultFilterValues });
  };

  return (
    <ComponentContext.Provider
      value={{
        totalItems,
        documents,
        loadPage,
        pageLoadParams,
        loading,
        onFilterChange,
        filterApplied,
        clearAllFilters,
        onArchiveFileSelect,
        filterOptions,
        filterValues: pageLoadParams.filterValues || defaultFilterValues,
        error,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useArchiveFilesContext = () => useContext(ComponentContext);
